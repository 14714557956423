import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    PointPara: '⟶',
    ParaOne:
      'Additionally, prioritizing and planning can be challenging for the Procurement team, especially when sourcing requests come frequently. Procurement intake automation allows quick collaboration with peers and stakeholders, on sourcing requests, and starts every project with strong and centralized requests.',
    ParaTwo:
      'Raindrop’s spend intake allows reduced cycle times, avoids non-compliant spending, and enhances visibility across all channels, while automatically maintaining the approval audit trail. As business stakeholders initiate their request for external spend requirements, powerful workflows behind the scenes, ensure that each request is sent to the right person at the right time, for review. Raindrop’s intake process swiftly moves from submission, approval, collaboration, tracking, and finally to closure.',
    HeadingOne: 'Why Spend Intake Automation is Important?',
    ParaThree:
      'Today’s dynamic work environment requires more functional teams to be involved in each purchase/contract if the company is to realize the desired ROI and guard against risk. With the increase in spending, compliance, and risk at the top of priority, sourcing requests require interaction with different departments like finance, legal, IT, and data security teams, etc. Raindrop’s spend intake forms empower teams by providing a digitized and simplified way to submit sourcing requests while providing an instant real-time 360-degree view of the request.',
    ParaFour:
      'Raindrop’s spend intake centralizes the initiation of purchasing requests and ushers them through lateral approval management, with complete all-level tracking across procurement, sourcing, legal, IT, data security, budget owners, and other teams. Raindrop’s intake-to-procure forms provide a complete audit trail for approvals with time stamps. Raindrop’s spend intake empowers businesses to streamline procurement processes and provide transparency across teams. Quick approvals, better decision-making, and access to data enable smooth functioning.',
    HeadingOnePointOne: 'Strategic Sourcing',
    ParaFive:
      'Strategic sourcing through procurement automation eliminates manual approvals across diverse teams mainly legal, IT, finance, and other teams. At Raindrop, we make it easy for the end-users to request a particular purchase before vendor engagement and provide visibility in your spending.',
    HeadingOnePointTwo: 'Ease of Use',
    ParaSix:
      'When employees need to buy something to help run their departmental needs, the user experience from starting the process to submitting their requests should be easy and not require any hand-holding. Raindrop spend intake forms are very easy to configure and use. No coding skills are required to create a new form from scratch or to use an existing intake form to submit requests. The cutting-edge UI guides the users in every step to properly submit the respective requests. With Raindrop’s ease of use, the current status is proactively updated so every requestor transparently sees the status of their requests. Further increasing continued use and generating compliance with company policy.',
    HeadingOnePointThree: 'Eliminate Manual Errors',
    ParaSeven:
      'Save time with reduced manual tracking approval requests across all teams. With procurement intake forms and automation, project owners spend less time checking the status quo. Raindrop allows for mitigating compliance issues and ensuring spend control with quick time to action, by providing visibility. Easily capturing spend intake needs allows finance, procurement, and legal teams to gain reporting into what and who in your company is buying from which external supplier.',
    HeadingOnePointFour: 'Increased Productivity',
    ParaEight:
      'With procurement intake automation, automatically submit and track sourcing requests while significantly reducing cycle times. With just a few clicks, you can automatically get approvals from different departments, send reminders for any request that sits within a department for review or approval, and collaborate with the internal team by utilizing the chat feature. From the requestor submitting the request, the Sourcing team which is working on the request, to different departmental stakeholders responsible for approving the request, keep all different functions tied in a simple and digitized way through Raindrop intake forms.',
    HeadingOnePointFive: 'Dashboards to Track Requests and Tasks',
    ParaNine:
      'Raindrop provides dashboards where you can see all the requests that are assigned to you as well as the requests submitted by you in a simple and intuitive way. This eliminates the requirement for users to keep track of their tasks and users. All are designed to increase productivity and reduce cycle times.',
    HeadingOnePointSix: 'Reducing Approval Bottlenecks',
    ParaTen:
      'Raindrop’s spend intake forms can be associated with powerful and easy-to-create workflows that run behind the scenes. The workflows automatically route the request to the right resources at the right time. Simply setup-up workflows with an approval hierarchy to make sure the request is routed to the right authorities in the right sequence. These benefits increase adherence to company policy, and preferred supplier spending and support cost reduction initiatives due to capturing the stakeholder’s needs before the goods or services are required.',
    HeadingFour: 'To Conclude',
    ParaEighteen:
      'Raindrop’s spend intake and registration forms contribute to maintaining internal and external spend in control, leading to more savings. The forms are intuitive, engaging, and powerful, to efficiently run smooth, transparent, and quick workflows in an organization.',
  },
]
